import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { SearchLink } from '@graphcommerce/magento-search'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefault,
  LayoutDefaultProps,
  iconLocation,
  NavigationFab,
  MenuFabSecondaryItem,
  PlaceholderFab,
  IconSvg,
  DesktopNavItem,
  iconChevronDown,
  NavigationProvider,
  NavigationOverlay,
  useNavigationSelection,
  useMemoDeep,
  NavigationNodeType,
  useFabSize,
  responsiveVal,
  iconSearch,
  iconCart,
} from '@graphcommerce/next-ui'
import iconPhone from '@graphcommerce/next-ui/icons/phone.svg'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Divider, Fab } from '@mui/material'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { DesktopTopHeader } from './DesktopHeader'
import { Footer } from './Footer'
import { FooterLinks } from './FooterLinks'

import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'

export type LayoutNavigationProps = Partial<LayoutQuery> &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const { footer, footerLinksLists, menu, mainMenu, children, ...uiProps } = props

  const fabIconSize = useFabSize('responsive')

  const selection = useNavigationSelection()
  const router = useRouter()

  const mainMenuItems = useMemo(
    () =>
      mainMenu?.pageLinks.map((item) => ({
        id: item.title.toLowerCase(),
        name: item.title,
        href: item.url,
      })) ?? [],
    [mainMenu?.pageLinks],
  )

  return (
    <>
      <NavigationProvider
        selection={selection}
        items={useMemoDeep(
          () => [
            <SearchLink
              href='/search'
              onClick={() => selection.set(false)}
              sx={(theme) => ({
                width: `calc(100% - ${theme.spacing(4)})`,
                m: 2,
                mb: theme.spacings.xs,
              })}
              aria-label={i18n._(/* i18n */ 'Search...')}
            >
              <Trans id='Search...' />
            </SearchLink>,
            { id: 'home', name: <Trans id='Home' />, href: '/' },
            {
              id: 'werkgever',
              name: <Trans id='Werkgever' />,
              href: '/zakelijk-leasen/het-lease-fietsplan',
            },
            {
              type: NavigationNodeType.BUTTON,
              id: 'werknemer',
              name: <Trans id='Werknemer' />,
              childItems: [
                {
                  id: 'fiets-uitzoeken',
                  name: <Trans id='Ik mag een fiets uitzoeken' />,
                  href: '/zakelijk-leasen/ik-mag-een-fiets-uitzoeken',
                },
                {
                  id: 'werkgever-niet-aangesloten',
                  name: <Trans id='Mijn werkgever is nog niet aangesloten' />,
                  href: '/zakelijk-leasen/werkgever-hoe-werkt-het',
                },
              ],
            },
            ...mainMenuItems,
            {
              type: NavigationNodeType.BUTTON,
              id: 'fietsen',
              name: <Trans id='Alle fietsen' />,
              childItems: [...magentoMenuToNavigation(menu, false)],
            },
            <Divider sx={(theme) => ({ my: theme.spacings.xs })} />,
            <MenuFabSecondaryItem
              key='zakelijk-leasen'
              icon={null}
              sx={{ '& .MuiListItemIcon-root': { display: 'none' } }}
              href='/zakelijk-leasen'
            >
              Zakelijk leasen
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='private-fietslease'
              icon={null}
              sx={{ '& .MuiListItemIcon-root': { display: 'none' } }}
              href='/private-fietslease'
            >
              Private fietslease
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='inloggen-partners'
              icon={null}
              sx={{ '& .MuiListItemIcon-root': { display: 'none' } }}
              href='/inloggen-partners'
            >
              B2B login
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='contact'
              icon={null}
              sx={{ '& .MuiListItemIcon-root': { display: 'none' } }}
              href='/contact'
            >
              Contact
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='locator'
              icon={<IconSvg src={iconLocation} size='medium' />}
              href='/partners'
            >
              <Trans id='Partners' />
            </MenuFabSecondaryItem>,
            <MenuFabSecondaryItem
              key='service'
              icon={<IconSvg src={iconPhone} size='medium' />}
              href='/service'
            >
              <Trans id='Customer Service' />
            </MenuFabSecondaryItem>,
          ],
          [menu, mainMenuItems, selection],
        )}
      >
        <NavigationOverlay
          stretchColumns={false}
          variantSm='left'
          sizeSm='full'
          justifySm='start'
          itemWidthSm='90vw'
          variantMd='left'
          sizeMd='full'
          justifyMd='start'
          itemWidthMd='360px'
          mouseEvent='hover'
          itemPadding='sm'
          sx={{
            '& ul>li.column1>a span': {
              fontSize: `${responsiveVal(22, 26)} !important`,
            },
          }}
        />
      </NavigationProvider>

      <LayoutDefault
        {...uiProps}
        sx={(theme) => ({
          gridTemplateRows: `auto auto auto 1fr auto`,
          [theme.breakpoints.up('md')]: {
            '& .LayoutDefault-fabs': {
              marginTop: `calc((${theme.appShell.headerHeightMd} + ${fabIconSize}) / 2 * -1)`,
            },
            '& .DesktopNavBar-scroller': {
              justifyContent: 'safe center',
            },
          },
        })}
        noSticky={router.asPath.split('?')[0] === '/'}
        beforeHeader={<DesktopTopHeader />}
        header={
          <>
            <Logo />
            <DesktopNavBar>
              <DesktopNavItem variant='h5' href='/zakelijk-leasen/het-lease-fietsplan'>
                <Trans id='Werkgever' />
              </DesktopNavItem>

              <DesktopNavItem variant='h5' onClick={() => selection.set(['werknemer'])}>
                <Trans id='Werknemer' />
                <IconSvg src={iconChevronDown} />
              </DesktopNavItem>

              {mainMenu?.pageLinks.map((item) => (
                <DesktopNavItem variant='h5' key={item.url} href={item.url}>
                  {item.title}
                </DesktopNavItem>
              ))}

              <DesktopNavItem variant='h5' onClick={() => selection.set(['fietsen'])}>
                <Trans id='Alle fietsen' />
                <IconSvg src={iconChevronDown} />
              </DesktopNavItem>
            </DesktopNavBar>

            <DesktopNavActions>
              {!router.pathname.startsWith('/search') ? (
                <Fab
                  href='/search'
                  aria-label={i18n._(/* i18n */ 'Search...')}
                  size='large'
                  color='inherit'
                >
                  <IconSvg src={iconSearch} size='large' />
                </Fab>
              ) : (
                <PlaceholderFab />
              )}
              <Fab
                href='/partners'
                aria-label={i18n._(/* i18n */ 'Locator')}
                size='large'
                color='inherit'
              >
                <IconSvg src={iconLocation} size='large' />
              </Fab>
              <Fab
                href='/service'
                aria-label={i18n._(/* i18n */ 'Account')}
                size='large'
                color='inherit'
              >
                <IconSvg src={iconPhone} size='large' />
              </Fab>
              {/* The placeholder exists because the CartFab is sticky but we want to reserve the space for the <CartFab /> */}
              <PlaceholderFab />
            </DesktopNavActions>
          </>
        }
        footer={
          <>
            {footerLinksLists && <FooterLinks footerLinksLists={footerLinksLists} />}
            <Footer footer={footer} />
          </>
        }
        cartFab={<CartFab icon={<IconSvg src={iconCart} size='large' />} />}
        menuFab={
          <NavigationFab
            sx={{
              '& button': { backgroundColor: 'background.dark' },
              '& button:hover, & button:focus': { backgroundColor: 'background.dark' },
            }}
            onClick={() => selection.set([])}
          />
        }
      >
        {children}
      </LayoutDefault>
    </>
  )
}
